<template>
  <v-container>
    <BulkConfirmationDialog :dialog="bulkConfirmationDialogIsOpen" @closeBulkConfirmationDialog="closeBulkConfirmationDialog()" @getRegistrantList="getRegistrantList()" />

    <v-container class="my-4 white" grid-list-xs outlined>
      <v-row>
        <v-col class="my-auto">
          <v-btn class="text-overline mr-2" color="red darken--2 white--text" @click="toggleFilterDisplay">
            <v-icon left>mdi-filter</v-icon>
            Filters
          </v-btn>
          <v-btn class="text-overline mr-2" color="blue darken--2 white--text" @click="openBulkConfirmationPrompt">
            <v-icon left>mdi-email</v-icon>
            Send Bulk Confirmation
          </v-btn>

          <download-excel
            class="d-inline-flex"
            :data="users"
            :fields="exportedHeaders"
            :before-generate="startExport"
            :before-finish="finishExport"
            worksheet="Decode Registrants"
            :name="uniqueFilename"
          >
            <v-btn id="exportBtn" class="text-overline mr-2" color="blue darken--2 white--text" :disabled="isExporting">
              <template v-if="isExporting">Exporting...<v-progress-circular indeterminate color="primary" class="ms-2" :size="16"></v-progress-circular></template>
              <template v-else>Export Excel</template>
            </v-btn>
          </download-excel>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expand-transition>
            <v-container v-show="filterIsDisplayed">
              <v-row>
                <v-col cols="2">
                  <v-radio-group dense>
                    <template v-slot:label>
                      <p class="text-overline mb-0">Year Registered</p>
                    </template>
                    <v-checkbox
                      v-for="year in years"
                      :key="year"
                      v-model="selectedYear"
                      :label="year.toString()"
                      :value="year"
                      :disabled="tableIsLoading"
                      hide-details
                      @change="getRegistrantList"
                    ></v-checkbox>
                  </v-radio-group>
                </v-col>
                <v-col cols="2">
                  <v-radio-group v-model="selectedStatusFilter" :disabled="tableIsLoading" dense @change="getRegistrantList">
                    <template v-slot:label>
                      <p class="text-overline mb-0">
                        Status
                        <v-btn v-if="!!selectedStatusFilter" class="ml-2" color="danger" icon @click="clearSelectedStatusFilter">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </p>
                    </template>
                    <v-radio v-for="status in statuses" :key="status" :value="status" :label="status"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="2">
                  <v-radio-group v-model="selectedIsEmailConfirmedFilter" :disabled="tableIsLoading" dense @change="getRegistrantList">
                    <template v-slot:label>
                      <p class="text-overline mb-0">
                        Email address confirmed
                        <v-btn v-if="!!selectedIsEmailConfirmedFilter" class="ml-2" color="danger" icon @click="clearSelectedIsEmailConfirmedFilter">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </p>
                    </template>
                    <v-radio value="true" label="Yes"></v-radio>
                    <v-radio value="false" label="No"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="2">
                  <v-radio-group v-model="selectedIsAttendingFilter" :disabled="tableIsLoading" dense @change="getRegistrantList">
                    <template v-slot:label>
                      <p class="text-overline mb-0">
                        User is attending
                        <v-btn v-if="!!selectedIsAttendingFilter" class="ml-2" color="danger" icon @click="clearSelectedIsAttendingFilter">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </p>
                    </template>
                    <v-radio value="true" label="Yes"></v-radio>
                    <v-radio value="false" label="No"></v-radio>
                  </v-radio-group>

                  <v-radio-group
                    v-if="selectedIsAttendingFilter && selectedIsAttendingFilter !== 'false'"
                    v-model="selectedIsFinalConfirmedFilter"
                    :disabled="tableIsLoading"
                    dense
                    @change="getRegistrantList"
                  >
                    <template v-slot:label>
                      <p class="text-overline mb-0">
                        User is confirmed his/her attendance
                        <v-btn v-if="!!selectedIsFinalConfirmedFilter" class="ml-2" color="danger" icon @click="clearSelectedIsFinalConfirmedFilter">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </p>
                    </template>
                    <v-radio value="true" label="Yes"></v-radio>
                    <v-radio value="false" label="No"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col v-if="selectedIsAttendingFilter && selectedIsAttendingFilter !== 'false'" cols="2">
                  <v-radio-group>
                    <template v-slot:label>
                      <p class="text-overline mb-0">Sessions</p>
                    </template>
                    <v-checkbox v-model="selectedIsAttendingAMSession" :disabled="tableIsLoading" label="AM Session" hide-details @change="getRegistrantList"></v-checkbox>
                    <v-checkbox v-model="selectedIsAttendingPMSession" :disabled="tableIsLoading" label="PM Session" hide-details @change="getRegistrantList"></v-checkbox>
                    <v-checkbox v-model="selectedIsAttendingPlenary" :disabled="tableIsLoading" label="Plenary" hide-details @change="getRegistrantList"></v-checkbox>
                  </v-radio-group>

                  <v-radio-group>
                    <template v-slot:label>
                      <p class="text-overline mb-0">Invitations</p>
                    </template>
                    <v-checkbox v-model="selectedIsHolRegistrant" :disabled="tableIsLoading" label="Hands on Lab" hide-details @change="getRegistrantList"></v-checkbox>
                    <v-checkbox v-model="selectedIsGigRegistrant" :disabled="tableIsLoading" label="Get It Girl" hide-details @change="getRegistrantList"></v-checkbox>
                  </v-radio-group>
                </v-col>
                <v-col cols="2">
                  <v-radio-group v-model="selectedUserIsPreviousAttendeeFilter" :disabled="tableIsLoading" dense @change="getRegistrantList">
                    <template v-slot:label>
                      <p class="text-overline mb-0">
                        User is previous attendee
                        <v-btn v-if="!!selectedUserIsPreviousAttendeeFilter" class="ml-2" color="danger" icon @click="clearSelectedUserIsPreviousAttendeeFilter">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </p>
                    </template>
                    <v-radio value="true" label="Yes"></v-radio>
                    <v-radio value="false" label="No"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete v-model="selectedRoleListFilter" :disabled="tableIsLoading" :items="roleListFilter" clearable dense label="Role" @change="getRegistrantList"> </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete v-model="selectedIndustryFilter" :disabled="tableIsLoading" :items="industryListFilter" clearable dense label="Industry" @change="getRegistrantList">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete v-model="selectedFunctionFilter" :disabled="tableIsLoading" :items="functionListFilter" clearable dense label="Function" @change="getRegistrantList">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete v-model="selectedRoleFunctionFilter" :disabled="tableIsLoading" :items="roleFunctionListFilter" clearable dense label="Function Role" @change="getRegistrantList">
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-expand-transition>
        </v-col>
      </v-row>
      <v-data-table
        :loading="tableIsLoading"
        :headers="headers"
        :items="users"
        :search="search"
        item-key="userId"
        class="elevation-1 table-cursor"
        :single-select="singleSelect"
        show-select
        @click:row="openUserDetailsDialog"
      >
        <template v-slot:item.name="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
        <template v-slot:item.action="{ item }">
          <div v-if="!item.status">
            <div v-if="item.emailConfirmed && !item.isConfirmed && !item.isRejected">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mr-2" color="primary" v-bind="attrs" v-on="on" @click.stop="openConfirmationPrompt(item.userId)">
                    <v-icon>mdi-sticker-check-outline</v-icon>
                  </v-btn>
                </template>
                <span>Send confirmation email</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="error" icon v-bind="attrs" v-on="on" @click.stop="openDeclinePrompt(item.userId)">
                    <v-icon>mdi-block-helper</v-icon>
                  </v-btn>
                </template>
                <span>Decline user</span>
              </v-tooltip>
            </div>
            <div v-else-if="!item.emailConfirmed && !item.isConfirmed && !item.isRejected">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="error" icon v-bind="attrs" v-on="on" @click.stop="openDeclinePrompt(item.userId)">
                    <v-icon>mdi-block-helper</v-icon>
                  </v-btn>
                </template>
                <span>Decline user</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="info" icon v-bind="attrs" v-on="on" @click.stop="openQRDialog(item.email)">
                    <v-icon>mdi-qrcode-scan</v-icon>
                  </v-btn>
                </template>
                <span>Show QR</span>
              </v-tooltip>
            </div>
          </div>
        </template>
        <template v-slot:item.email="{ item }">
          {{ item.email }}
        </template>
        <template v-slot:item.dateCreated="{ item }">
          {{ item.dateCreated | readableDate }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip class="ma-2" :color="item.emailConfirmed ? 'success' : 'warning'" label outlined>
            <v-icon left> {{ item.emailConfirmed ? 'mdi-email-check' : 'mdi-email-remove' }} </v-icon>
            {{ item.emailConfirmed ? 'Email Verified' : 'Unverified Email' }}
          </v-chip>

          <v-chip class="ma-2" :color="item.isLastYearAttendee ? 'primary' : 'success'" label outlined>
            <v-icon left> {{ item.isLastYearAttendee ? 'mdi-page-previous' : 'mdi-new-box' }} </v-icon>
            {{ item.isLastYearAttendee ? 'Previous Attendee' : 'New Attendee' }}
          </v-chip>

          <div v-if="item.isRejected">
            <v-chip class="ma-2" color="pink" label text-color="white">
              <v-icon left> mdi-account-cancel </v-icon>
              Rejected
            </v-chip>
          </div>

          <div v-else-if="item.isPending">
            <v-chip class="ma-2" color="primary" label text-color="white">
              <v-icon left> mdi-account-clock </v-icon>
              Pending
            </v-chip>

            <v-chip v-if="item.isAttendingAMSession || item.isAttendingAMSession" class="ma-2" color="deep-purple" label text-color="white">
              <v-icon left> mdi-book-check </v-icon>
              {{ attendingIdentifier(item.isAttendingAMSession, item.isAttendingPMSession, item.isAttendingPlenary) }}
            </v-chip>
          </div>

          <div v-else-if="item.isConfirmed && !item.isAttending">
            <v-chip class="ma-2" color="green" label text-color="white">
              <v-icon left> mdi-account-check </v-icon>
              Confirmed
            </v-chip>
          </div>

          <div v-else-if="item.isAttending">
            <v-chip class="ma-2" color="deep-purple" label text-color="white">
              <v-icon left> mdi-book-check </v-icon>
              {{ attendingIdentifier(item.isAttendingAMSession, item.isAttendingPMSession, item.isAttendingPlenary) }}
            </v-chip>
          </div>

          <div v-if="item.isFinalConfirmed && item.isAttending">
            <v-chip class="ma-2" color="green" label text-color="white">
              <v-icon left> mdi-send-check </v-icon>
              Attendance Confirmed
            </v-chip>
          </div>

          <div v-if="item.isHolRegistrant">
            <v-chip small class="ma-2" color="teal darken-4" label text-color="white">
              <v-icon small left> mdi-desktop-classic</v-icon>
              Hands On Lab Registrant
            </v-chip>
          </div>

          <div v-if="item.isGigRegistrant">
            <v-chip small class="ma-2" color="pink darken-4" label text-color="white">
              <v-icon small left> mdi-face-woman </v-icon>
              Get it Girl Registrant
            </v-chip>
          </div>
        </template>
      </v-data-table>
    </v-container>
    <confirmation-dialog v-model="confirmationDialogIsOpen" :loading="isLoading" @closeConfirmationDialog="closeConfirmationDialog()">
      <div slot="header">Confirm user attendance?</div>
      <div slot="body">An email will be sent to the user notifying their attendance for this year's Decode is confirmed.</div>
      <div slot="actions">
        <v-btn text color="error" :disabled="isLoading" @click="closeConfirmationDialog()">Cancel</v-btn>
        <v-btn text color="primary" :disabled="isLoading" @click="sendAttendanceConfirmedEmail()">Confirm</v-btn>
      </div>
    </confirmation-dialog>
    <confirmation-dialog v-model="resendVerificationDialogIsOpen" :loading="isLoading" @closeConfirmationDialog="closeConfirmationDialog()">
      <div slot="header">Resend verification email?</div>
      <div slot="body">An email will be sent to the user that would let them verify their email.</div>
      <div slot="actions">
        <v-btn text color="error" :disabled="isLoading" @click="closeConfirmationDialog()">Cancel</v-btn>
        <v-btn text color="primary" :disabled="isLoading" @click="resendVerificationEmail()">Confirm</v-btn>
      </div>
    </confirmation-dialog>
    <confirmation-dialog v-model="declineDialogIsOpen" :loading="isLoading" @closeConfirmationDialog="closeConfirmationDialog()">
      <div slot="header">Decline user attendance?</div>
      <div slot="body">
        <p>An email will be sent to the user that they are declined and will not be able to join this year's Decode.</p>
        <p>Please provide the reason why the user will be rejected. This would not be sent to the user and will only be used for future reference.</p>
        <v-form ref="reasonForRejectionForm" v-model="rejectionFormIsValid">
          <v-select v-model="reasonForRejection" :items="rejectionReasons" :rules="reasonForRejectionRules" label="Reason for rejection" dense outlined></v-select>
          <v-textarea v-if="reasonForRejection === 'Others'" v-model="otherReason" :rules="otherReasonForRejectionRules" outlined name="input-7-4" label="Notes"></v-textarea>
        </v-form>
      </div>
      <div slot="actions">
        <v-btn text color="error" :disabled="isLoading" @click="closeConfirmationDialog()">Cancel</v-btn>
        <v-btn text color="primary" :disabled="isLoading" @click="sendDeclineEmail()">Confirm</v-btn>
      </div>
    </confirmation-dialog>
    <user-details-dialog v-if="userDetails" v-model="userDetailsDialogIsOpen" @closeUserProfileDialog="closeUserProfileDialog()">
      <div slot="header">User Profile</div>
      <div slot="body">
        <v-card class="mx-auto mb-3" outlined>
          <v-card-text>
            <v-container class="black--text">
              <v-row>
                <v-col cols="12" lg="6">
                  <p class="text-h6 mb-0 font-weight-regular">Basic info</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Name</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.firstName }} {{ userDetails.lastName }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Date Registered</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.dateCreated | readableDate }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Status</p>
                </v-col>
                <v-col class="my-auto">
                  <div v-if="userDetails.isRejected">
                    <v-chip class="ma-2" color="pink" label text-color="white">
                      <v-icon left> mdi-account-cancel </v-icon>
                      Rejected
                    </v-chip>
                  </div>

                  <div v-else-if="userDetails.isPending">
                    <v-chip class="ma-2" color="primary" label text-color="white">
                      <v-icon left> mdi-account-clock </v-icon>
                      Pending
                    </v-chip>

                    <v-chip v-if="userDetails.isAttendingAMSession || userDetails.isAttendingAMSession" class="ma-2" color="deep-purple" label text-color="white">
                      <v-icon left> mdi-book-check </v-icon>
                      {{ attendingIdentifier(userDetails.isAttendingAMSession, userDetails.isAttendingPMSession, userDetails.isAttendingPlenary) }}
                    </v-chip>
                  </div>

                  <div v-else-if="userDetails.isConfirmed && !userDetails.isAttending">
                    <v-chip class="ma-2" color="green" label text-color="white">
                      <v-icon left> mdi-account-check </v-icon>
                      Confirmed
                    </v-chip>
                  </div>

                  <div v-else-if="userDetails.isAttending">
                    <v-chip class="ma-2" color="deep-purple" label text-color="white">
                      <v-icon left> mdi-book-check </v-icon>
                      {{ attendingIdentifier(userDetails.isAttendingAMSession, userDetails.isAttendingPMSession, userDetails.isAttendingPlenary) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="userDetails.isRejected">
                <v-divider></v-divider>
              </v-row>
              <v-row v-if="userDetails.isRejected">
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Rejection reason</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.rejectionReason }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card class="mx-auto mb-3" outlined>
          <v-card-text>
            <v-container class="black--text">
              <v-row>
                <v-col cols="12" lg="6">
                  <p class="text-h6 mb-0 font-weight-regular">Contact info</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Email address</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.email }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Mobile Number</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.phoneNumber }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card class="mx-auto" outlined>
          <v-card-text>
            <v-container class="black--text">
              <v-row>
                <v-col cols="12" lg="6">
                  <p class="text-h6 mb-0 font-weight-regular">Professional info</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Company</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.company }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Role</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.role }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Industry</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.industry }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">User Function</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.function }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12" lg="5">
                  <p class="text-overline mb-0 grey--text text--darken-1">Role function</p>
                </v-col>
                <v-col class="my-auto">
                  <p class="text-body-1 mb-0">{{ userDetails.roleFunction }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
      <div slot="actions">
        <v-btn text color="primary" @click="closeUserProfileDialog()">Close</v-btn>
      </div>
    </user-details-dialog>
    <q-r-dialog v-model="qrDialogIsOpen" @closeQRDialog="closeQRDialog()">
      <div slot="header">User QR Code</div>
      <div slot="body">
        <div v-if="selectedUserQr && selectedUserQr.qr">
          <div class="d-flex flex-column align-center printing">
            <div id="forQrPrinting" class="d-none">
              <div class="qrcode-container"></div>
              <p id="qrcode-name" class="qrcode-name">{{ selectedUserQr.fullName }}</p>
            </div>
            <v-img :width="300" :height="300" cover :src="selectedUserQr.qr" alt="QR Code"></v-img>
            <h3>
              {{ selectedUserQr.fullName }}
            </h3>
          </div>
          <v-row align="center" justify="space-around" class="mt-3">
            <v-btn tile @click="printQR(selectedUserQr.qr, selectedUserQr.fullName)">
              Print
              <v-icon right> mdi-printer </v-icon>
            </v-btn>
          </v-row>
        </div>
        <div v-else>
          <p>No QR available</p>
        </div>
      </div>
      <div slot="actions">
        <v-btn text color="primary" @click="closeQRDialog()">Close</v-btn>
      </div>
    </q-r-dialog>
    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';
import DownloadExcel from 'vue-json-excel';
import { format } from 'date-fns';
import { Printd } from 'printd';

import ConfirmationDialog from '@/components/Global/ConfirmationDialog';
import BulkConfirmationDialog from '@/components/BulkConfirmationDialog';
import UserDetailsDialog from '@/components/UserDetailsDialog';
import QRDialog from '@/components/QRDialog';

export default {
  components: {
    DownloadExcel,
    ConfirmationDialog,
    BulkConfirmationDialog,
    UserDetailsDialog,
    QRDialog,
  },
  filters: {
    readableDate: (value) => {
      if (!value) return '';
      return format(new Date(value), 'MMMM d, y');
    },
  },
  data: () => ({
    roleFunctionListFilter: [
      'I make strategic decisions that directly influence and drive the direction of the business',
      'I make management decisions (operational/functional/administrative) that contribute to the achievement of business targets',
      'I make technical decisions that affect the daily operational activities of the business',
    ],
    selectedRoleFunctionFilter: '',
    functionListFilter: [
      'Administrative/Middle Management',
      'Business Professional',
      'Executive Management',
      'Faculty/Educator/Training Professional',
      'Information Security',
      'IT Operations',
      'IT Specialist',
      'Software Development',
      'Student',
      'Others',
    ],
    selectedFunctionFilter: '',
    industryListFilter: [
      'Banking, Finance, Insurance, and Holdings',
      'Business and Professional Services',
      'Education',
      'Energy',
      'Entertainment, Leisure, and Travel',
      'Government',
      'Healthcare',
      'Hotel and Restaurant',
      'Manufacturing and Industrial',
      'Media and Publishing',
      'Military and Defense',
      'Real Estate and Property Development',
      'Religious Institution',
      'Retail, Trading, and Consumer Goods',
      'Technology and IT Services',
      'Telecommunications',
      'Transport and Shipping',
      'Others',
    ],
    selectedIndustryFilter: '',
    roleListFilter: [
      'Account Associate/Manager',
      'Administrator',
      'Associate',
      'Business Development Manager',
      'Change Manager',
      'Communications Officer / Manager',
      'Finance Officer / Manager',
      'General Manager',
      'HR Officer / Manager',
      'Operations Officer / Manager',
      'Project Coordinator / Manager',
      'Sales / Marketing Manager',
      'Service Manager',
      'Supervisor / Team Leader',
      'Accountant',
      'Analyst',
      'Audit Manager',
      'Banking / Finance Professional',
      'BPO Professional',
      'Consultant',
      'Coordinator',
      'Reports Developer',
      'Risk & Compliance Officer / Manager',
      'Chairperson',
      'Chief Operations Officer',
      'Chief Technology Officer',
      'Director',
      'Founder / Co - Founder',
      'President / CEO',
      'Vice President',
      'Dean',
      'Department Chair',
      'Instructor',
      'Principal',
      'Professor',
      'Program / Learning Coordinator',
      'Training Officer / Manager',
      'Anti,Malware Specialist / Analyst',
      'Forensics Specialist / Analyst',
      'Information Security Professional',
      'Network Security Professional',
      'Security Administrator',
      'Security Analyst / Consultant',
      'Threat Analyst / Researcher',
      'Computer Operator',
      'IT Engineer / Staff',
      'Information Systems Analyst / Officer',
      'IT Officer / Administrator',
      'Service / Helpdesk Staff',
      'System Administrator',
      'Technical Support Staff',
      'Content Creator / Developer',
      'Database Administrator',
      'Database Analyst / Consultant',
      'Graphic Designer / Artist',
      'IT Research and Development Specialist',
      'Network Administrator',
      'Network Analyst / Engineer',
      'Artist',
      'Athlete',
      'Chef / Culinary Specialist',
      'Scientist / Inventor',
      'Editor / Publisher',
      'Engineer / Mechanic / Technician',
      'Entrepreneur / Business Owner / Investor',
      'Medical Doctor / Professional',
      'Police / Military Officer',
      'Writer / Journalist',
      'Developer / Programmer',
      'Software Engineer',
      'QA / Software Testing Analyst / Engineer',
      'Solution Architect',
      'Website Designer',
      'Undergraduate',
      'Postgraduate',
      'Others',
    ],
    selectedRoleListFilter: '',
    selectedUserIsPreviousAttendeeFilter: '',
    selectedIsEmailConfirmedFilter: '',
    selectedIsAttendingFilter: 'true',
    selectedIsFinalConfirmedFilter: '',
    selectedIsAttendingAMSession: '',
    selectedIsAttendingPMSession: '',
    selectedIsAttendingPlenary: '',
    selectedIsHolRegistrant: '',
    selectedIsGigRegistrant: '',
    statuses: ['Pending', 'Confirmed', 'Declined'],
    selectedStatusFilter: 'Confirmed',
    years: [2023, 2022, 2021, 2020, 2019, 2018],
    selectedYear: [2023, 2022, 2021, 2020, 2019, 2018],
    backendEndpoint: process.env.VUE_APP_BACKEND_ENDPOINT,
    snackbarText: '',
    snackbar: false,
    snackbarTimeout: 5000,
    rejectionFormIsValid: true,
    reasonForRejectionRules: [(v) => !!v || 'This field is required'],
    otherReasonForRejectionRules: [(v) => !!v || 'This field is required'],
    search: '',
    confirmationDialogIsOpen: false,
    bulkConfirmationDialogIsOpen: false,
    resendVerificationDialogIsOpen: false,
    declineDialogIsOpen: false,
    isLoading: false,
    isExporting: false,
    userIdToEmail: '',
    users: [],
    headers: [
      {
        text: 'Name',
        value: 'name',
        align: 'start',
      },
      {
        text: 'Date Registered',
        value: 'dateCreated',
      },
      {
        text: 'Email Address',
        value: 'email',
      },
      {
        text: 'Company',
        value: 'company',
      },
      {
        text: 'Role',
        value: 'role',
      },
      // {
      //   text: 'Industry',
      //   value: 'industry',
      // },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
      },
    ],
    exportedHeaders: {
      Email: 'email',
      FirstName: 'firstName',
      LastName: 'lastName',
      PhoneNumber: 'phoneNumber',
      IsAttendingAMSession: 'isAttendingAMSession',
      IsAttendingPMSession: 'isAttendingPMSession',
      IsAttendingPlenary: 'isAttendingPlenary',
      isFinalConfirmed: 'isFinalConfirmed',
      IsHolRegistrant: 'isHolRegistrant',
      IsGigRegistrant: 'isGigRegistrant',
      Status: {
        callback: (item) => {
          if (item.isRejected) return 'Rejected';
          if (item.isPending && !item.isConfirmed) return 'Pending';
          if (item.isConfirmed && !item.isAttending) return 'Confirmed';
          if (item.isAttending && item.isConfirmed) return 'Attending';
          return '';
        },
      },
      DateRegistered: {
        callback: (item) => {
          try {
            const parsedDate = new Date(item.dateCreated);
            const options = {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              timeZone: 'UTC',
            };
            return new Intl.DateTimeFormat('en-US', options).format(parsedDate);
          } catch {
            return '';
          }
        },
      },
      Company: 'company',
      Industry: 'industry',
      Role: 'role',
      Function: 'function',
      RoleFunction: 'roleFunction',
    },
    rejectionReasons: ['User is a student', 'Duplicate record', 'Competitor', 'Others'],
    reasonForRejection: '',
    otherReason: '',
    userDetailsDialogIsOpen: false,
    qrDialogIsOpen: false,
    userDetails: null,
    tableIsLoading: true,
    singleSelect: false,
    filterIsDisplayed: true,
    selectedUserQr: null,
    cssPrinting: `
      .printing {
        background-image: url(https://decode.azureedge.net/public/qr/b7285c01/190933fe.png);
        background-position: center center;
        font-family: sans-serif;
        max-width: 600px;
        border: solid 1px #ccc;
        text-align: center;
        padding: 1rem;
        margin: 2rem auto;
        padding-bottom: 2rem;
        border-radius: 5px;

        /* NOTE:
         * Use these styles to get background colors when
         ing.
         * Pass it to Printd.print()
         */
        background-color: lavender;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }

      button {
        background-color: #fff;
        border: solid 1px #6a6a6a;
        padding: .5rem 1rem;
        border-radius: 3px;
      }

      pre { color: #c7254e; font-size: .85rem; }
    `,
  }),
  computed: {
    uniqueFilename: () => {
      const now = new Date();
      const datePart = now.toISOString().slice(0, 10).replace(/-/g, '');
      const timePart = now.toISOString().slice(11, 19).replace(/:/g, '');
      return `decode-registrants-${datePart}-${timePart}.xls`;
    },
  },
  created() {
    this.getRegistrantList();
  },
  methods: {
    attendingIdentifier(isAttendingAMSession, isAttendingPMSession, isAttendingPlenary) {
      if (isAttendingAMSession && isAttendingPMSession) return 'Attending AM and PM sessions';
      if (isAttendingAMSession) return 'Attending AM session';
      if (isAttendingPMSession) return 'Attending PM session';
      if (isAttendingPlenary) return 'Attending Plenary';
      return 'Attending';
    },
    handleNullFilters() {
      if (this.selectedRoleListFilter === null) {
        this.selectedRoleListFilter = '';
      }
      if (this.selectedIndustryFilter === null) {
        this.selectedIndustryFilter = '';
      }
      if (this.selectedFunctionFilter === null) {
        this.selectedFunctionFilter = '';
      }
      if (this.selectedRoleFunctionFilter === null) {
        this.selectedRoleFunctionFilter = '';
      }
    },
    async getRegistrantList() {
      try {
        if (this.selected) this.handleNullFilters();
        this.reloadTable();

        if (this.selectedIsAttendingFilter === 'false' || this.selectedIsAttendingFilter === '') {
          this.selectedIsAttendingPMSession = '';
          this.selectedIsAttendingAMSession = '';
          this.selectedIsAttendingPlenary = '';
          this.selectedIsHolRegistrant = '';
          this.selectedIsGigRegistrant = '';
        }

        if (!this.selectedIsAttendingPlenary) {
          this.selectedIsAttendingPlenary = '';
        }

        if (!this.selectedIsAttendingPMSession) {
          this.selectedIsAttendingPMSession = '';
        }

        if (!this.selectedIsAttendingAMSession) {
          this.selectedIsAttendingAMSession = '';
        }

        let selectedYearParams = '';
        for (let i = 0; i < this.selectedYear.length; i += 1) {
          if (i === 0) selectedYearParams += `year=${this.selectedYear[i]}`;
          else selectedYearParams += `&year=${this.selectedYear[i]}`;
        }

        let fetchRegistrantEndpoint = `${this.backendEndpoint}/api/admin/registrants?`;

        fetchRegistrantEndpoint += `${selectedYearParams}&status=${this.selectedStatusFilter}&emailConfirmed=${this.selectedIsEmailConfirmedFilter}`;
        fetchRegistrantEndpoint += `&isAttending=${this.selectedIsAttendingFilter}&isAttendingPlenary=${this.selectedIsAttendingPlenary}&isAttendingAMSession=${this.selectedIsAttendingAMSession}&isAttendingPMSession=${this.selectedIsAttendingPMSession}`;
        fetchRegistrantEndpoint += `&isHolRegistrant=${this.selectedIsHolRegistrant}&isGigRegistrant=${this.selectedIsGigRegistrant}&isLastYearAttendee=${this.selectedUserIsPreviousAttendeeFilter}`;
        fetchRegistrantEndpoint += `&role=${this.selectedRoleListFilter}&industry=${this.selectedIndustryFilter}&function=${this.selectedFunctionFilter}&roleFunction=${this.selectedRoleFunctionFilter}`;

        const res = await axios.get(fetchRegistrantEndpoint);
        this.users = res.data;
        this.displaySnackbarReminder();
      } catch {
        this.snackar = false;
        this.snackbarText = 'There was an error with the request. Try again later.';
        this.snackbar = true;
      } finally {
        this.tableIsLoading = false;
      }
    },
    openConfirmationPrompt(userId) {
      this.confirmationDialogIsOpen = true;
      this.userIdToEmail = userId;
    },
    openBulkConfirmationPrompt() {
      this.bulkConfirmationDialogIsOpen = true;
    },
    openDeclinePrompt(userId) {
      this.declineDialogIsOpen = true;
      this.userIdToEmail = userId;
    },
    openResendVerificationPrompt() {
      this.resendVerificationDialogIsOpen = true;
    },
    async sendAttendanceConfirmedEmail() {
      try {
        const payload = {
          id: this.userIdToEmail,
        };
        this.isLoading = true;
        await axios.post(`${this.backendEndpoint}/api/admin/confirm`, payload).then(() => {
          this.closeConfirmationDialog();
          this.confirmUserOnRegistrantList();
          this.snackbarText = 'Attendance confirmation email has been sent.';
          this.snackbar = true;
        });
      } catch {
        this.snackbarText = 'There was an error in confirming the attendance.';
        this.snackbar = true;
      } finally {
        this.isLoading = false;
      }
    },
    confirmUserOnRegistrantList() {
      if (!this.userIdToEmail) return;
      const userIndex = this.users.findIndex((user) => user.userId === this.userIdToEmail);
      this.users[userIndex].isConfirmed = true;
    },
    declineUserOnRegistrantList() {
      if (!this.userIdToEmail) return;
      const userIndex = this.users.findIndex((user) => user.userId === this.userIdToEmail);
      this.users[userIndex].isRejected = true;
      this.users[userIndex].rejectionReason = this.rejectionReason;
      this.users[userIndex].otherReason = this.otherReason;
    },
    resendVerificationEmail() {
      this.isLoading = true;
      console.log('resend verification email');
      setTimeout(() => {
        this.isLoading = false;
        this.closeConfirmationDialog();
        this.snackbarText = 'Verification email has been sent.';
        this.snackbar = true;
      }, 500);
    },
    async sendDeclineEmail() {
      try {
        if (!this.$refs.reasonForRejectionForm.validate()) return;
        const payload = {
          id: this.userIdToEmail,
          reason: this.reasonForRejection,
          remarks: this.otherReason,
        };
        this.isLoading = true;
        await axios.post(`${this.backendEndpoint}/api/admin/decline`, payload).then(() => {
          this.declineUserOnRegistrantList();
          this.snackbarText = 'Rejection email has been sent.';
          this.snackbar = true;
          this.closeConfirmationDialog();
        });
      } catch {
        console.log('error');
      } finally {
        this.isLoading = false;
      }
    },
    resetDeclineAttendanceFields() {
      this.reasonForRejection = '';
      this.otherReason = '';
      if (!this.$refs.reasonForRejectionForm) return;
      this.$refs.reasonForRejectionForm.resetValidation();
    },
    closeConfirmationDialog() {
      this.confirmationDialogIsOpen = false;
      this.resendVerificationDialogIsOpen = false;
      this.declineDialogIsOpen = false;
      this.resetDeclineAttendanceFields();
    },
    closeBulkConfirmationDialog() {
      this.bulkConfirmationDialogIsOpen = false;
    },
    openUserDetailsDialog(user) {
      this.userDetailsDialogIsOpen = true;
      this.userDetails = user;
    },

    closeUserProfileDialog() {
      this.userDetailsDialogIsOpen = false;
      this.userDetails = {};
    },
    async openQRDialog(email) {
      this.qrDialogIsOpen = true;
      try {
        const response = await axios.get(`${this.backendEndpoint}/api/admin/registrant-qr?email=${email}`);
        this.selectedUserQr = response.data;
      } catch (err) {
        console.log('err :>> ', err);
      }
    },
    closeQRDialog() {
      this.qrDialogIsOpen = false;
    },
    displaySnackbarReminder() {
      this.snackbarText = 'You may click on the table rows to see more user details.';
      this.snackbar = true;
    },
    reloadTable() {
      this.users = [];
      this.tableIsLoading = true;
    },
    toggleFilterDisplay() {
      this.filterIsDisplayed = !this.filterIsDisplayed;
    },
    async clearSelectedStatusFilter() {
      this.selectedStatusFilter = '';
      await this.getRegistrantList();
    },
    async clearSelectedIsEmailConfirmedFilter() {
      this.selectedIsEmailConfirmedFilter = '';
      await this.getRegistrantList();
    },
    async clearSelectedIsAttendingFilter() {
      this.selectedIsAttendingFilter = '';
      this.selectedIsAttendingAMSession = '';
      this.selectedIsAttendingPMSession = '';
      this.selectedIsFinalConfirmedFilter = '';
      await this.getRegistrantList();
    },

    async clearSelectedIsFinalConfirmedFilter() {
      this.selectedIsFinalConfirmedFilter = '';
      await this.getRegistrantList();
    },

    async clearSelectedUserIsPreviousAttendeeFilter() {
      this.selectedUserIsPreviousAttendeeFilter = '';
      await this.getRegistrantList();
    },
    startExport() {
      this.isExporting = true;
    },
    finishExport() {
      this.isExporting = false;
    },
    printQR(qrUrl, name) {
      let fontSize = '38px';

      if (name.length >= 36) {
        fontSize = '34px';
      }
      const d = new Printd();

      // Tip: texts & urls are supported
      const style = [
        `#forQrPrinting {
        border: 1px dotted;
        width: 7cm;
        height: 6cm;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        padding: 10px;
        margin-right: 15px;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
      .qrcode-container{
        width: 80px;
        height: 80px;
        background: url(${qrUrl});
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        padding-top: 5px;
      }
      .qrcode-name {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${fontSize};
        font-family: Arial;
        margin-top:20px;
        margin-bottom:0;
        padding:5px 2px;

        text-align: center;
      }`,
      ];
      const scripts = ['(() => console.log("Hello from IFrame!"))()'];

      // Get an HTMLElement reference
      const el = document.getElementById('forQrPrinting');
      // Trigger the print dialog on demand
      const printCallback = ({ launchPrint }) => launchPrint();

      d.print(el, style, scripts, printCallback);
    },
  },
};
</script>

<style>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
