<template>
  <v-dialog :value="value" width="600" @click:outside="closeDialog()" @keydown.esc="closeDialog()">
    <v-card>
      <template slot="progress">
        <v-progress-linear color="primary" height="5" indeterminate></v-progress-linear>
      </template>
      <v-card-title>
        <slot name="header"></slot>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    userDetails: {
      type: Object,
      default: null,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeQRDialog');
    },
  },
};
</script>
